<template>
  <v-container>
    <v-btn text @click="$router.go(-1)" ><v-icon
        left
        dark
      >
        mdi-backburger
    </v-icon> Zurück zu Referenzen</v-btn>
    <ImageGallery v-model="galleryData"/>
  </v-container>
</template>
<script>
import ImageGallery from '@/components/ImageGallery.vue';

export default {
  name: 'wasserschaden-mainz-kastel',
  components: {
    ImageGallery,
  },
  data: () => ({
    galleryData: {
      imagePath: 'https://medo-sanierung.de/public_files/img/references/wasserschaden-mainz-kastel',
      imageCount: 16,
      imageFileFormat: '.jpg',
      name: 'Wasserschaden in Mainz-Kastel',
      description:
          'Nach dem Wasserschaden in einem Reiheneckhaus in Mainz-Kastel musste nach Trocknungsmaßnahmen die Wiederherstellung fortgesetzt werden. Hauptsächlich waren Verputz-, Maler- und Fliesenarbeiten notwendig. Im Zuge der Sanierung wurde das Metall-Treppengeländer angeschliffen und lackiert.',
    },
    //
  }),
};
</script>
